.container {
  height:  48px;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: solid 2px #e5e5e5;
  background-color: #FFFFFF;
  z-index: 50;
}

.container button {
  font-size: 0.6rem;
}

.logo {
  width: 24px;
  height: 24px;
  color: inherit;
}
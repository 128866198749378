.containerPatientNoProviderCard {
	background-color: transparent;
	color: #424242;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	text-align: center;
	padding: 32px;
}

.containerPatientNoProviderCard p {
	color: #757575;
}

.containerPatientNoProviderCard svg {
	color: #9e9e9e;
	font-size: 4rem;
	margin-bottom: 16px;
}

.containerPatientNoProviderCard button {
	margin-top: 32px;
}
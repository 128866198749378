.containerMessageOutgoing {
	display: flex;
  	align-items: flex-end;
  	margin-right: 0px;
  	margin-left: auto;
}

.containerMessageOutgoing svg {
	color: #757575;
	font-size: 1.2rem;
	margin-right: 8px;
}

.chatBubble {
	background-color: #2979ff;
	color: white;
	padding: 8px 16px 8px 16px;
	display: inline-flex;
	white-space: pre-wrap;
	margin-left: 45px;
}

.chatBubble a {
	color: white;
}

.chatBubbleWrapper {
	display: flex;
	align-items: center;
}

.chatContent {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.messageDate {
	margin-top: 4px;
	font-size: 0.8rem;
  	text-align: right;
}

.containerPatientAppointmentCard {
	padding: 16px;
	border:  solid 2px #e5e5e5;
	border-radius: 24px;
	display:  flex;
	justify-content: space-between;
}

.containerPatientAppointmentCard h5:not(:last-child) {
	margin-bottom: 4px;
}

.containerPatientAppointmentCard p {
	font-size: 1rem;
	color: #757575;
}

.amountPaid {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

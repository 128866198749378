/*Styling overwrite for conversational form module*/
.ChatStyle #conversational-form {
}

.ChatStyle #conversational-form .conversational-form-inner {
  padding: 0 14px;
}

.ChatStyle p {
  font-size: 1rem !important;
  line-height: 20px !important;
  padding: 9px 17px 11px 17px;
  /*word-break: break-all;*/
}

.ChatStyle textarea,
.ChatStyle input {
  box-shadow: none !important;
  border-top: solid 2px #e0e0e0;
}

.ChatStyle a {
  font-weight: 600;
}


.ChatStyle .conversational-form-inner {
  padding:  0!important;
}

cf-chat {
  padding:  0 16px !important;
}

cf-input-control-elements {
  padding:  0!important;
  border-top:  solid 2px #E0E0E0;
}

cf-list {
  padding:  16px 16px 6px 16px;
}

cf-list:empty {
  display: none;
}

.ChatStyle .disableInput textarea,
.ChatStyle .disableInput input {
  background-color: #e0e0e0;
}

.ChatStyle .hideInput,
.ChatStyle .hideInput{
  display: none;
}

/*.ChatStyle textarea:focus,*/
.ChatStyle textarea:hover,
/*.ChatStyle input:focus,*/
.ChatStyle input:hover {
  box-shadow: none;
  border-color: #bdbdbd;
  background-color: #F5F5F5;
}

/* Remove arrows from number inputs */
.ChatStyle input::-webkit-outer-spin-button,
.ChatStyle input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ChatStyle .inputWrapper.disableInput input,
.ChatStyle .inputWrapper.disableInput textarea {
  pointer-events: none !important;
}

.ChatStyle .inputWrapper input,
.ChatStyle .inputWrapper textarea {
  height:  74px!important;
  width:  calc(100% - 74px);
}


.ChatStyle cf-input {
  margin-bottom: 0px;
  margin-top: 4px;
}

.ChatStyle cf-input-button {
  background-color: #448AFF !important;
  height:  74px!important;
  width:  74px!important;
}

.ChatStyle cf-input-button:hover {
  background-color: #2962ff !important;
}

.ChatStyle .inputWrapper:focus-within cf-input-button {
  background-color: #2962ff!important;
}

.ChatStyle cf-input-button:active {
  background-color: #304ffe !important;
  opacity: 100 !important;
}

/*Adds loading spinner when input is being submitted*/
.ChatStyle cf-input-button.loading div.cf-icon-progress {
  background-image: url('../../../assets/svg/icon_horiz_dots.svg');
  background-size:  24px 34px;
}

.ChatStyle cf-input-button.loading {
  background-color: #bdbdbd !important;
  cursor: not-allowed;
  pointer-events: none;
}

.ChatStyle .inputWrapper.disableInputButton cf-input-button {
  background-color: #bdbdbd !important;
  cursor: not-allowed;
  pointer-events: none;
}

.ChatStyle cf-list {
  height: unset !important;
  max-height:  48vh!important;
  width: 100% !important;
  overflow-x: hidden;
  overflow-y: scroll;
  transform:  translateX(0)!important;
  cursor: unset;
}

.ChatStyle cf-list-button {
  display: none;
}

.ChatStyle cf-radio-button,
.ChatStyle cf-checkbox-button,
.ChatStyle cf-button {
  border-color: #2979FF;
  border-width:  2px;
  color: #2979FF!important;
  font-size: 1rem;
  background-color: transparent!important;
  /*display:  table!important;*/
  float: left;
  /*margin:10px;*/
}

@media (hover: hover) {
  .ChatStyle cf-radio-button:hover:not([checked="checked"]),
  .ChatStyle cf-checkbox-button:hover:not([selected="selected"]),
  .ChatStyle cf-button:hover:not([checked="checked"]) {
    background-color: #BBDEFB !important;
  }
}

.ChatStyle cf-radio-button:focus,
.ChatStyle cf-button.cf-checkbox-button:focus {
  background-color: #BBDEFB !important;
}

.ChatStyle cf-button.cf-checkbox-button[selected="selected"]:focus {
  background-color: #2355de!important;
  border: solid 2px #82B1FF;
}


.ChatStyle cf-radio-button.cf-button[checked="checked"],
.ChatStyle cf-button.cf-checkbox-button[selected="selected"] {
  background-color: #2979FF !important;
  border-color:  #2979FF;
}

.ChatStyle cf-radio,
.ChatStyle cf-checkbox {
  border-color: #2979FF !important;
  background-color: transparent!important;
}

.ChatStyle cf-radio-button.cf-button[checked="checked"] cf-radio,
.ChatStyle cf-button.cf-checkbox-button[selected="selected"] cf-checkbox {
  border-color:  #2979FF!important;
}

.ChatStyle cf-radio-button:not([checked="checked"]) cf-radio:after,
.ChatStyle cf-button.cf-checkbox-button:not([selected="selected"]) cf-checkbox:after {
  display: none!important;
}

.ChatStyle .cf-button.cf-checkbox-button:hover cf-checkbox {
  background-color: transparent;
}

.ChatStyle cf-chat-response {
  max-width: 90%;
  padding-left: 48px;
}

.ChatStyle cf-chat-response.user {
  padding-right: 48px;
}

.ChatStyle cf-chat-response.user p {
  background-color: #2979ff !important;
}

.ChatStyle cf-chat-response.user p:hover {
  background-color: #2962ff !important;
}

.ChatStyle cf-chat-response.user thumb {
  background-color: #2979ff;
  color: #2979ff;
  background-size: 20px;
}

.ChatStyle.disableBackBtn cf-chat-response.user {
  pointer-events: none;
}

.ChatStyle.disableBackBtn cf-chat-response.user thumb {
  display: none;
}

.ChatStyle cf-chat-response.user thumb:hover {
  background-color: #2962ff;
}

.ChatStyle cf-chat-response thumb {
  height: 40px;
  width: 40px;
}

.ChatStyle .cf-progressBar {
  background-color: #e5e5e5;
  position:  fixed;
  top:  55px;
  left: unset;
  height:  2px;
}

.ChatStyle .cf-progressBar .bar {
  /*background-image: linear-gradient(to right, #FD477B , #EBE190);*/
  background-image: linear-gradient(to right, #ebe190, #fd477b);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height:  5px;
  position:  relative;
  top: -2px;
}

.ChatStyle input::placeholder,
.ChatStyle input:hover::placeholder {
  /* Most modern browsers support this now. */
  color: #bdbdbd;
}

.ChatStyle textarea::placeholder,
.ChatStyle textarea:hover::placeholder {
  /* Most modern browsers support this now. */
  color: #bdbdbd;
}

.ChatStyle cf-chat-response:not(:nth-last-of-type(2)).user.can-edit {
  pointer-events: none;
}
.ChatStyle cf-chat-response:not(:nth-last-of-type(2)).user.can-edit thumb {
  display: none;
}

.ChatStyle .conversational-form--enable-animation cf-chat-response text>p {
  will-change: auto!important;
}

/*Styling overwrite for conversational form module*/

.containerPatientPortalAppointmentsUpcoming {
	padding: 48px 16px 64px 16px;
}

.containerPatientPortalAppointmentsUpcoming > div:not(:last-child),
.futureAppointments > div:not(:last-child) {
	margin-bottom: 24px;
}

.containerPatientPortalAppointmentsUpcoming h3:not(:last-child) {
	margin-bottom: 48px;
}

.futureAppointments {
	margin-top: 64px;
}

/*Responsive Layout*/
@media only screen and (min-width: 600px) {
	.containerPatientPortalAppointmentsUpcoming {
		max-width: 1200px;
		margin: auto;
	}
}
/*Responsive Layout*/
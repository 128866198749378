.containerCompleteSessionFeedbackCard:not(:last-child) {
	border-bottom: solid 2px #e5e5e5;
}

.containerCompleteSessionFeedbackCard h5:not(:last-child) {
	margin-bottom: 4px;
	cursor: pointer;
}

.containerCompleteSessionFeedbackCard p {
	color: #757575;
}

.header {
	display:  flex;
	gap: 16px;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
}

.timeToComplete {
	display: flex;
	align-items: center;
}

.timeToComplete svg {
	margin-right: 4px;
	font-size: 1.2rem;
}
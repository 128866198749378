.container {
	padding:  16px;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	position: relative;
}

.container h5 {
	margin: 0;
	font-size: 1.1rem;
	font-weight: 500;
}

.container p {
	color: #757575;
}

.container .cancelBtn {
	margin-top: 32px;
}

.appointmentTime {
	display:  flex;
	align-items: center;
}

.appointmentTime svg {
	margin-left:  8px;
	color: #757575;
}

.container .chip {
	font-family: inherit;
	color:  #ffffff;
	margin-top: 8px;
}

.container .chip:not(:last-child) {
	margin-right: 8px;
}

.container .chip svg {
	color: #ffffff;
}

.container .chipsContainer {
	display:  flex;
	flex-wrap: wrap;
	margin-top: 8px;
}


.content {
	margin-top: 32px;
}

.content svg {
	margin-right: 8px;
}

.content.red, .content.red p {
	color: #D50000;
}

.container .content p {
	margin-top: 4px;
	margin-bottom: 0;
}

.header {
	display: flex;
}

.header p {
	margin-bottom: 0px!important;
}

.header h5:not(:last-child) {
	margin-bottom: 4px;
}

.header > *:not(:last-child) {
	margin-right: 16px;
}

.row {
	display: flex;
	align-items: center;
}
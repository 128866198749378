.container {
    padding: 16px;
    display: flex;
    gap: 16px;
}

.container:not(:last-child) {
	border-bottom: solid 2px #e5e5e5;
}


.container svg {
    color: #00BFA5;
}

.container p {
	color: #757575;
}
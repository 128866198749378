.containerMessageIncoming {
	display: flex;
	align-items: flex-end;
}

.chatBubble {
	background-color: #e5e5e5;
	padding: 8px 16px 8px 16px;
	display: inline-flex;
	white-space: pre-wrap;
	margin-right: 45px;
}

.messageDate {
	margin-top: 4px;
	margin-left: 16px;
	font-size: 0.8rem;
}

.senderName {
	margin-left: 16px;
	font-size: 0.8rem;
	font-weight: 500;
}
.containerPatientNextAppointmentPendingCard {
	background-color: transparent;
	color: #424242;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	text-align: center;
	padding: 32px;
}

.containerPatientNextAppointmentPendingCard p {
	color: #757575;
}

.containerPatientNextAppointmentPendingCard svg {
	color: #4524242;
	margin-bottom: 8px;
}


.container {
	padding-bottom: 48px;
	background-color: #FFFFFF;
	position: relative;
	max-width: 1200px;
	margin: auto;
}

.container .closeBtn {
	border: solid 2px #e5e5e5;
}

.container .fab {
	position: fixed;
	bottom: 16px;
	right: 16px;
}

.header {
	padding: 24px 16px 24px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

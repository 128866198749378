.containerSlider {
	z-index: 10;
	width:  100%;
	box-sizing: border-box;
	position: absolute;
	bottom:  0;
	max-height: 95%;
	overflow: scroll;
	background-color: #FFFFFF;
	border-radius: 24px 24px 0px 0px;
	padding: 16px 16px 48px 16px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.containerSlider .closeBtn {
	border: solid 2px #e5e5e5;
}

.contentSlider {
	margin-top: 24px;
}

.backdrop {
	left: unset!important;
	right: unset!important;
	bottom: unset!important;
	height: 100%;
	width: 100%;
}
.containerConsentAlert {
	padding: 16px;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
}

.containerConsentAlert p {
	font-weight: 300;
}

.containerConsentAlert .btn {
	margin-top: 16px; 
}
.containerPatientScheduleSessionCard {
	background-color: transparent;
	color: #424242;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	text-align: center;
	padding: 32px;
}

.containerPatientScheduleSessionCard p {
	color: #757575;
}

.containerPatientScheduleSessionCard svg {
	color: #424242;
	margin-bottom: 8px;
}

.containerPatientScheduleSessionCard button {
	margin-top: 32px;
}
.containerPatientPortalAppointmentsContent {
	margin-bottom: 48px;
}


.inboxHeader {
	border-bottom:  solid 2px #e5e5e5;
}

.inboxHeader h2 {
	margin: 32px 16px 32px 16px;
}

.containerSkeleton {
	padding: 32px 16px 48px 16px;
}

/*Responsive Layout*/
@media only screen and (min-width: 600px) {
	.inboxHeader h2 {
		max-width: 1200px;
		margin:  auto;
		padding:  32px 16px 0px 16px;
	}
	.tabs {
		max-width: 1200px;
		margin:  auto;	
	}
}
/*Responsive Layout*/
.containerPaymentMethodCard {
	border-radius: 24px;
	border: solid 2px #e5e5e5;
	padding: 16px;
	position: relative;
}

.containerPaymentMethodCard h5 {
	text-transform: capitalize;
}

.containerPaymentMethodCard h5 span {
	color: #616161;
}

.containerPaymentMethodCard p {
	color: #757575;
}

button.btnMenu {
	position: absolute;
	top: calc(50% - 20px);
	right: 4px;
}
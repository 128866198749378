.MuiTabs-root {
}

.MuiTabs-root .MuiTab-root {
	font-family: 'Airbnb Cereal';
	min-width: 48px;
	color: #757575;
}

.MuiTabs-root .MuiTab-root.Mui-selected {
	font-weight:  800;
	color: #2979FF;
}

.MuiTabs-root .MuiTab-root:hover {
	font-weight:  800;
}


.MuiTabs-root .MuiTabs-indicator {
	display:  none;
}


@media only screen and (min-width: 600px) {
}
.containerPatientForgotPassword {
	padding: 48px 16px 48px 16px;
}

.containerPatientForgotPassword p:not(:last-child) {
	margin-bottom: 32px;
}

.containerPatientForgotPassword .textField {
	margin-bottom: 16px;
}

.containerPatientForgotPassword .btnSubmit {
	margin-top: 32px;
}
.react-calendar {
	width: 600px;
	border: none;
	font-family: 'Airbnb Cereal';
	color: #424242;
}

.react-calendar button {
	border-radius: 24px;
	font-family: 'Airbnb Cereal';
	color: #424242;
}

button.react-calendar__month-view__days__day {
	height: 44px;
	width: 44px;
	max-width: 44px!important;
	margin: calc((100% / 7 - 44px)/2);
	padding: 0;
	background: #F5F5F5;
}


.react-calendar__tile--active,
.react-calendar__tile--active:enabled,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #2979FF;
	color: #FFFFFF;
	font-weight: 600;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #2962FF!important;
}

.react-calendar__tile:disabled,
.react-calendar__navigation button:disabled {
	background: transparent;
	color: #BDBDBD;
}

.react-calendar__tile--now:hover {
	background: #EEEEEE!important;
}

.react-calendar__month-view__weekdays__weekday {
	/*min-width: 44px;*/
}

.react-calendar__month-view__days__day--weekend {
	color: #424242;
}

.react-calendar__navigation .react-calendar__navigation__next2-button,
.react-calendar__navigation .react-calendar__navigation__prev2-button {
	display: none;
}

.react-calendar__navigation .react-calendar__navigation__next-button,
.react-calendar__navigation .react-calendar__navigation__prev-button {
	font-size: 1.3rem;
}

.react-calendar__navigation .react-calendar__navigation__label {
	margin: 0px 8px 0px 8px;
	pointer-events: none;
}

.react-calendar__year-view .react-calendar__tile {
	max-width: calc(100% / 3 - 8px)!important;
	flex-basis: calc(100% / 3 - 8px)!important;
	padding: 16px;
	margin: 4px;
}

button.react-calendar__tile--hasActive  {
    background: #2979FF;
    color: #FFFFFF;
    font-weight: 600;
}

button.react-calendar__tile--hasActive:hover  {
    background: #2962FF!important;
}
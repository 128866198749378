.containerAddPayment {
	padding: 16px;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	margin-top: 32px;
}

.containerAddPayment h5:not(:last-child) {
	margin-bottom: 24px;
}

.containerAddPayment button {
	margin-top: 0px;
}

.containerPaymentMethod {
	margin-top: 32px;
}
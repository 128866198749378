.containerRescheduleAppointment {
	padding: 24px 16px 48px 16px;
	background-color: #ffffff;
}

.containerRescheduleAppointment .header > p {
	margin-bottom: 32px!important;
}

.containerRescheduleAppointment .closeBtn {
	border: solid 2px #e5e5e5;
	margin-bottom: 24px;
}

.calendarContainer {
	margin-bottom: 48px;
}

.header {
	margin-bottom: 48px;
}

.containerRescheduleAppointment h2:not(:last-child) {
	margin-bottom: 16px;
}

.containerRescheduleAppointment p:not(:last-child) {
	margin-bottom: 48px;
}

.containerRescheduleAppointment .timeSlotsContainer h4:not(:last-child) {
	margin-bottom: 32px;
}

.dialog p:not(:last-child) {
	margin-bottom:  8px;
}

.frequencyContainer {
	margin-top: 24px;
}

.radioGroup {
	margin-bottom: 32px!important;
}


@media only screen and (min-width: 600px) {
	.containerRescheduleAppointment {
		max-width: 800px;
		margin: auto;
	}
}
.slot {
	display: flex;
}

.slot:not(:last-child) {
	margin-bottom: 16px;
}

button.confirmBtn {
	opacity: 0;
	width: 0;
	margin-left: 0px;
	position: absolute;
    -webkit-transition: width 0.2s linear;
       -moz-transition: width 0.2s linear;
            transition: width 0.2s linear;
}

button.confirmBtn.showConfirmBtn {
	opacity:  100;
	width: 100%;
	margin-left: 8px;
	position: relative;
}

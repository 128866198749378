.containerConfirmSchedule {
	padding: 24px 16px 48px 16px;
	background-color: #ffffff;
}

.containerConfirmSchedule .closeBtn {
	border: solid 2px #e5e5e5;
}

.header {
	margin-bottom: 32px;
}
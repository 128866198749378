.containerPatientPortalSettingsContent {
  padding: 32px 16px 48px 16px;
  margin-bottom: 48px;
}

.containerPatientPortalSettingsContent h2:not(:last-child) {
  margin-bottom: 48px;
}

.section {
  border: solid 2px #e5e5e5;
  border-radius: 24px;
  padding: 0px 16px 0px 16px;
}

/*Responsive Layout*/
@media only screen and (min-width: 600px) {
  .containerPatientPortalSettingsContent {
    max-width: 1200px;
    margin: auto;
  }
}
/*Responsive Layout*/


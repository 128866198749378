.containerScheduleSessionCard {
	background-color: transparent;
	color: #424242;
	border: solid 2px #e5e5e5;
	border-radius:  24px;
	text-align: center;
	padding: 32px;
	padding-top:  42px;
}

.containerScheduleSessionCard p {
	color: #757575;
}

.containerScheduleSessionCard h5:not(:last-child) {
	margin-bottom: 8px;
}

.containerScheduleSessionCard svg {
	color: #424242;
	font-size: 2.5rem;
	margin-bottom: 16px;
}

.containerScheduleSessionCard .scheduleBtns {
	margin-top: 32px;
}

.containerScheduleSessionCard .scheduleBtns button:last-child {
	margin-top: 16px;
}
.MuiButtonBase-root.MuiFab-root {
	font-family: 'Airbnb Cereal';
	font-weight: 600;
}

.MuiFab-root.MuiFab-primary {
	background-color: #2779FF;
}

.MuiFab-root.MuiFab-primary:hover {
	background-color: #2962FF;
}

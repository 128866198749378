.containerChatAssessment {
	height:  calc(100% - 54px);
	position: absolute;
	bottom: 0;
	width: 100%;
}

.containerSkeleton {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 120px;
	left: 16px;
}

.containerSkeleton > *:first-child {
	margin-right: 16px;
}
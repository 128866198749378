.containerPatientInbox {
	position:  absolute;
	top:  0;
	bottom:  0;
	left:  0;
	right:  0;
}

.containerMessages {
	height: calc(100% - 73px);
	box-sizing: border-box;
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
	padding: 0px 16px 16px 16px;
	overflow-y: scroll;
	position: relative;
}

.containerTextArea {
	position:  absolute;
	bottom:  0;
	width:  100%;
	box-sizing: border-box;
	padding:  24px 16px 24px 16px;
	display: flex;
	align-items: flex-end;
	background-color: #ffffff;
	z-index: 10;
}

.containerTextArea .sendBtn{
	background-color: #2979FF;
	color:  #ffffff;
	margin-left: 8px;
}

.containerTextArea .sendBtn:focus, .sendBtn:hover{
	background-color: #2979FF;
}

.containerTextArea .sendBtn:disabled {
	background-color: #e5e5e5;
	color: #757575;
}

.date {
	align-self: center;
	font-weight: 500;
	font-size: 0.9rem;
	color: #757575;
	margin-top: 48px;
	margin-bottom: 0!important;
}

.textArea {
	width:  100%;
	box-sizing: border-box;
	padding: 8px 16px 8px 16px;
	font-family: inherit;
	font-size: 1rem;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	resize: none;
	outline: none;
}

.textArea:focus {
	border-color: #424242;
}

/*Responsive Layout*/
@media only screen and (min-width: 600px) {
	.containerPatientInbox {
		max-width: 1200px;
		margin: auto;
	}
}
/*Responsive Layout*/
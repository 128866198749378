.MuiButtonBase-root.MuiButton-root {
	box-shadow:  none;
	border-radius: 24px;
	font-family: 'Airbnb Cereal';
}

.MuiButton-root.MuiButton-contained {
	background-color:  #2979FF;
}

.MuiButton-root.MuiButton-contained:hover {
	background-color: #2962FF;
	box-shadow: none;
}

.MuiButton-root.MuiButton-containedSecondary {
	background-color:  #424242;
	color: #FFFFFF;
}

.MuiButton-root.MuiButton-containedSecondary:hover {
	background-color: #212121;
	box-shadow: none;
}

.MuiButton-root.MuiButton-outlined {
	border:  solid 2px #2979FF;
	color: #2979FF;
	font-weight: 600;
}

.MuiButton-root.MuiButton-outlined:hover {
	border:  solid 2px #2962FF;
	color: #2962FF;
}

.MuiButton-root.MuiButton-outlinedSecondary {
	border:  solid 2px #616161;
	color: #424242;
	font-weight: 600;
}

.MuiButton-root.MuiButton-outlinedSecondary:hover {
	border:  solid 2px #424242;
	color: #424242;
}

.MuiButton-root.MuiButton-textSecondary {
	color: #424242!important;
}

.MuiButton-root.MuiButton-textSecondary:hover {
	color: #424242;
}


.MuiButton-root.MuiButton-text {
	color: #2979FF;
}

.MuiButton-root.MuiButton-colorInherit,
.MuiButton-root.MuiButton-colorInherit:hover {
	color: inherit;
	border-color: inherit;
}


.MuiButton-root.MuiButton-outlined.MuiLoadingButton-root.MuiLoadingButton-loading {
	color: transparent;
	border: solid 2px rgba(0,0,0,0.2);
}

.MuiButton-root.MuiLoadingButton-root.MuiLoadingButton-loading {
	color: transparent;
	background-color: #E0E0E0;
}
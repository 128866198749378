.containerScheduleAppointment {
	padding: 24px 16px 48px 16px;
	background-color: #ffffff;
}

.containerScheduleAppointment .header h2:not(:last-child) {
	margin-bottom: 16px;
}

.containerScheduleAppointment .header h4:not(:last-child) {
	margin-bottom: 32px;
}

.containerScheduleAppointment .header p:not(:last-child) {
	margin-bottom: 48px;
}

.containerScheduleAppointment .timeSlotsContainer h4:not(:last-child) {
	margin-bottom: 32px;
}

.containerScheduleAppointment .closeBtn {
	border: solid 2px #e5e5e5;
}

.containerScheduleAppointment .closeBtn:not(:last-child) {
	margin-bottom: 48px;
}

.containerScheduleAppointment .btnBook:not(:first-child) {
	margin-top: 32px;
}

.calendarContainer {
	margin-bottom: 48px;
}

.header {
	margin-bottom: 32px;
}


@media only screen and (min-width: 600px) {
	.containerScheduleAppointment {
		max-width: 800px;
		margin: auto;
	}
}
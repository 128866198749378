.containerPatientLogIn {
	padding: 48px 16px 48px 16px;
}

.containerPatientLogIn h4:not(:last-child) {
	margin-bottom: 32px;
}

.containerPatientLogIn .textField {
	margin-bottom: 16px;
}

.containerPatientLogIn .btnSubmit {
	margin-top: 32px;
}

.containerOptions {
	margin-top: 48px;
}

.containerOptions > p {
	margin-top: 16px;
}
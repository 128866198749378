.containerPayment h2:not(:last-child) {
	margin-bottom: 8px;
}

.containerPayment .header {
	margin-bottom: 48px;
}

.containerAddPayment {
	margin-top: 48px;
}

button.btnSubmit {
	margin-top: 32px;
}


.contentElement:not(:last-child) {
	margin-bottom: 16px;
}



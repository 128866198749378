.containerPatientPortalHomeContent {
	padding: 32px 16px 112px 16px;
}

.containerPatientPortalHomeContent h1 {
	font-weight: 600;
	margin:  0;	
	margin-bottom: 32px;
}


.containerPatientPortalHomeContent h4:not(:first-child) {
	margin-top: 48px;
}

.containerPatientPortalHomeContent h4:not(:last-child) {
	margin-bottom: 24px;
}

.containerPatientPortalHomeContent .card  {
	padding:  16px;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
}

.containerPatientPortalHomeContent .card h5  {
	margin: 0;
	margin-bottom: 8px;
	font-size: 1.1rem;
	font-weight: 500;
}

.containerPatientPortalHomeContent .card p  {
	margin: 0;
}

/*.containerTherapists, .containerUpcomingAppointment {
	margin-top: 48px;
}*/

.containerTherapists > div:not(:last-child) {
	margin-bottom: 16px;
}

.welcomeCard {
	height: 256px;
	border-radius: 24px;
	border-radius: 24px;
	box-sizing: border-box;
}

.titleOverlay {
	width: 100%;
	height: 100%;
	border-radius: 24px;
	padding: 64px 32px 48px 32px;
	box-sizing: border-box;
	background: -moz-linear-gradient(45deg, rgba(240, 148, 51, 0.5) 0%, rgba(230, 104, 60, 0.5) 25%, rgba(220, 39, 67, 0.5) 50%, rgba(204, 35, 102, 0.5) 75%, rgba(188, 24, 136, 0.5) 100%);
  	background: -webkit-linear-gradient(45deg, rgba(240, 148, 51, 0.5) 0%, rgba(230, 104, 60, 0.5) 25%, rgba(220, 39, 67, 0.5) 50%, rgba(204, 35, 102, 0.5) 75%, rgba(188, 24, 136, 0.5) 100%);
  	background: -o-linear-gradient(45deg, rgba(240, 148, 51, 0.5) 0%, rgba(230, 104, 60, 0.5) 25%, rgba(220, 39, 67, 0.5) 50%, rgba(204, 35, 102, 0.5) 75%, rgba(188, 24, 136, 0.5) 100%);
  	background: -ms-linear-gradient(45deg, rgba(240, 148, 51, 0.5) 0%, rgba(230, 104, 60, 0.5) 25%, rgba(220, 39, 67, 0.5) 50%, rgba(204, 35, 102, 0.5) 75%, rgba(188, 24, 136, 0.5) 100%);
  	background: linear-gradient(45deg, rgba(240, 148, 51, 0.5) 0%, rgba(230, 104, 60, 0.5) 25%, rgba(220, 39, 67, 0.5) 50%, rgba(204, 35, 102, 0.5) 75%, rgba(188, 24, 136, 0.5) 100%);
}

.containerPatientPortalHomeContent .welcomeCard h4 {
	margin-bottom: 8px;
	color: #FFFFFF;
}

.containerPatientPortalHomeContent .welcomeCard p {
	font-weight: 500;
	color:  #FFFFFF;
}

@media only screen and (min-width: 900px) {
	.welcomeCard {
		height: 384px;
	}
	.welcomeCard h4 {
		font-size: 3rem!important;
		margin-bottom: 32px!important;
	}
	.welcomeCard p {
		font-size: 1.6rem;
	}
	.titleOverlay {
		padding-top: 64px;
	}
}

@media only screen and (min-width: 1200px) {
	.welcomeCard {
		height: 512px;
	}
	.titleOverlay {
		padding-top: 128px;
	}
}



/*Responsive Layout*/
@media only screen and (min-width: 600px) {
	.containerPatientPortalHomeContent {
		max-width: 1200px;
		margin: auto;
	}
}
/*Responsive Layout*/
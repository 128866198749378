.containerPatientPortalMessagesContent {
	position:  absolute;
	top:  0;
	bottom:  48px;
	width:  100%;
}

.containerMessagesNoProvider {
	padding:  32px 16px 48px 16px;
}

.inboxHeader {
	border-bottom:  solid 2px #e5e5e5;
}

.inboxHeader h2 {
	margin: 32px 16px 32px 16px;
}

.noProviderCard {
	padding:  16px;
	border:  solid 2px #e5e5e5;
	border-radius: 24px;
}

.noProviderCard {
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	text-align: center;
	padding: 32px;
}

.noProviderCard p {
	color: #757575;
}

.noProviderCard svg {
	color: #2979FF;
	font-size: 3rem;
	margin-bottom: 16px;
}


.panelMessages {
	/*height:  calc(100% - 155px);*/
	height:  calc(100% - 106px);
	position:  relative;
}

/*Responsive Layout*/
@media only screen and (min-width: 600px) {
	.containerMessagesNoProvider {
		max-width: 1200px;
		margin:  auto;
	}	
	.inboxHeader h2 {
		max-width: 1200px;
		margin:  auto;
		padding:  32px 16px 0px 16px;
	}
	.tabs {
		max-width: 1200px;
		margin:  auto;	
	}
}
/*Responsive Layout*/

.containerPatientAppointmentCard {
	
}

.containerPatientAppointmentCard h4 {
	margin-bottom: 16px;
}

.card {
	padding: 16px;
	border:  solid 2px #e5e5e5;
	border-radius: 24px;
	position: relative;
}

.containerPatientAppointmentCard h5:not(:last-child) {
	margin-bottom: 4px;
}

.header p {
	color: #757575;
	margin-bottom: 0px!important;
}

.containerPatientAppointmentCard .chip {
	font-family: inherit;
	color:  #ffffff;
	margin-bottom: 8px;
}

.containerPatientAppointmentCard .chip:not(:last-child) {
	margin-right: 8px;
}

.containerPatientAppointmentCard .chip svg {
	color: #ffffff;
}

.containerPatientAppointmentCard .chipsContainer {
	display:  flex;
	flex-wrap: wrap;
	margin-bottom: 8px;
}

.containerPatientAppointmentCard .rescheduleBtn {
	position: absolute;
	top: 16px;
	right: 16px;
	border: solid 2px #e5e5e5;
	color: #616161;
}

.containerPatientAppointmentCard .content p:not(:last-child) {
	margin-bottom: 16px;
}

.containerPatientAppointmentCard .videoBtn {
	margin-top:  16px;
}

.phoneMessage {
	margin-top: 16px;
	display:  flex;
	align-items: flex-end;
}

.phoneMessage svg {
	margin-right:  8px;
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.timeslot {
	background-color: rgba(0,0,0,0.05);
	border-radius: 12px;
	margin-top: 24px;
	padding: 16px;
	/*word-spacing: 0.2rem;*/
	font-weight: 500;
}

.timeslot p {
	font-weight:  300;
	color:  #757575;
}

.timeslot:not(:last-child) {
	margin-bottom: 24px;
}

.timeslotBtns {
	margin-top: 16px;
	color: #2979FF;
}

.timeslotBtns button:not(:last-child) {
	margin-right: 8px;
}

.header {
	display: flex;
}

.header:not(:last-child) {
	margin-bottom: 24px;
}

.header > *:first-child {
	margin-right: 16px;
}

.placeholderAppointment p {
	color: #757575;
}

.placeholderAppointment h5 {
	color: #757575;
	text-transform: uppercase;
	font-size: 0.8rem;
}
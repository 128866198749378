.containerPatientSignConsent {
	box-sizing: border-box;
	padding: 0px;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.containerPatientSignConsent h2:not(:last-child) {
	margin-bottom: 8px;
}

.containerPatientSignConsent .closeBtn {
	border: solid 2px #e5e5e5;
}

.containerPatientSignConsent .closeBtn:not(:last-child) {
	margin-bottom: 24px;
}

.containerPatientSignConsent h2:not(:last-child) {
	margin-bottom: 16px;
}

.containerPatientSignConsent .header {
	padding: 24px 16px 0px 16px;
	margin-bottom: 48px;
}

.containerPandaDoc {
	box-sizing: border-box;
	width: 100%;
	flex-grow: 4;
}

/*Responsive Layout*/
@media only screen and (min-width: 600px) {
  .containerPatientSignConsent  {
    max-width: 1200px;
    margin: auto;
  }
}
/*Responsive Layout*/

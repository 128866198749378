.containerFindProviderProfile {
  padding-bottom: calc(85.5px + 48px);
  background-color: #FFFFFF;
  position: relative;
  max-width: 1200px;
  margin: auto;
}

.containerFindProviderProfile .closeBtn {
  margin: 16px;
  margin-top: 24px;
  border: solid 2px #e5e5e5;
}

.containerProviderProfileBook {
  position: fixed;
  bottom:  0;
  left: 0;
  right: 0;
  padding:  16px;
  background-color: #ffffff;
  border-top: solid 2px #e5e5e5;
  display: flex;
  justify-content: space-between;
}

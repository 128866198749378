.container {
	padding: 16px;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	position: relative;
}

.container .appointmentBtn {
	position: absolute;
	top: 16px;
	right: 16px;
	border: 2px solid #e5e5e5;
	color: #616161;
}

.container .appointmentBtn:hover {
	background-color: #E5E5E5;
}

.chip {
	margin-top: 4px;
}

.header {
	display: flex;
}

.header h5:not(:last-child) {
	margin-bottom: 4px;
	cursor: pointer;
}

.header h5:hover {
	font-weight: 600;
	text-decoration: underline;
}

.header p {
	margin:  0;
	color: #757575;
}

.header img {
	cursor: pointer;
}

.header > *:first-child {
	margin-right: 16px;
}
.container {
	padding:  16px;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	position: relative;
}

.container h5 {
	margin: 0;
	font-size: 1.1rem;
	font-weight: 500;
}

.container p {
	margin-bottom: 0!important;
	color: #757575;
}

.containerModPolicy {
	padding-top: 24px;
	margin-top: 24px;
	border-top: solid 2px #e5e5e5;
}

.content {
	margin-top: 24px;
}

.content svg {
	margin-right: 8px;
}

.container .content p {
	margin-top: 4px;
	margin-bottom: 0;
}

.appointmentTime {
	display:  flex;
	align-items: center;
}

.appointmentTime svg {
	margin-left:  8px;
	color: #757575;
}

.header {
	display: flex;
}

.header h5:not(:last-child) {
	margin-bottom: 4px;
}

.header svg {
	color: #757575;
}

.header > *:not(:last-child) {
	margin-right: 16px;
}

.row {
	display: flex;
}

.row:not(:last-child){
	margin-bottom: 24px;
}

.success {
	color:  green;
}

.warning {
	color:  #FF3D00;
}

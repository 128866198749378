.container {
	border:  solid 2px #e5e5e5;
	border-radius:  24px;
	font-weight:  500;
	height:  100%;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

.content {
	padding:  0px 0px 0px 0px;
}

.content .row {
	padding:  16px 16px 16px 16px;
	/* flex-wrap: nowrap; */
}

.content .row:not(:last-child),
.scrollable:not(:last-child) {
	border-bottom:  solid 2px #e5e5e5;
}

.row {
	display:  flex;
	align-items:  flex-start;
	gap: 16px;
}

.scrollable {
	display:  flex;
	align-items:  center;
	overflow-x: scroll;
	white-space: nowrap
}

/*.container .row > *:not(:first-child) {
	margin-left:  16px;
}*/

.container .row p {
	margin:  0;
	font-size:  1.2rem;
}

.container .row small {
	font-weight: 300;
}

/* .container .row span {
	margin-left:  8px;
} */

.header {
	position:  relative;
	border-bottom: solid 2px #e5e5e5;
}

.headerContent  {
	display:  flex;
	align-items:  center;
	padding: 8px 32px 8px 16px;
	overflow-x: scroll;
}

.headerContent::-webkit-scrollbar,
.scrollable::-webkit-scrollbar  { 
    display: none;  /* Safari and Chrome */
}

.progressDeductible {
	width:  100%;
	margin-left:  0!important;
	margin-top:  24px;
}

.progressDeductible span {
	margin:  0!important;
}

.container .row .progressDeductible p:first-child {
	text-transform: uppercase;
	color:  #757575;
	font-weight: 500;
	font-size: 0.8rem;
}

.container .row .progressDeductible p:not(:first-child) {
	margin-top:  8px;
}

.overlay::before {
	background: linear-gradient(to right,rgba(0,0,0,0) 90%, #fff 100%);
    content: "\00a0";
    height: 100%;
    width: 100%;
    position: absolute;
    right:  0;
    top:  0;
    border-radius: 0px 24px 0px 0px;
    pointer-events: none;
}

.headerContent .row {
	padding-top: 16px;
	padding-bottom: 16px;
}

.headerContent .row:not(:last-child) {
	border-right: solid 2px #e5e5e5;
	padding-right: 16px;
}

.headerContent .row:not(:first-child) {
	padding-left: 16px;
}

.headerContent .row p {
	white-space: nowrap
}

.flag {
  width: 20px;
  aspect-ratio: 70 / 45;
  align-self: center;
  border-radius: 2px;
}

.traditional {
   background:
    linear-gradient(red 16.66%, orange 0 33.33%, yellow 0 50%, green 0 66.66%, blue 0 83.33%, indigo 0)
}
.containerPatientSettingsInput {
	margin-top: 16px;
	padding-bottom: 16px;
}

.containerPatientSettingsInput h5 {
	color: #616161;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


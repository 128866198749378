.containerFindProvider {
	padding: 24px 16px 48px 16px;
}

.containerFindProvider h2:not(:last-child) {
	margin-bottom: 8px;
}

.containerFindProvider .closeBtn {
	border: solid 2px #e5e5e5;
}

.containerFindProvider .closeBtn:not(:last-child) {
	margin-bottom: 24px;
}

.containerFindProvider h2:not(:last-child) {
	margin-bottom: 16px;
}

.containerFindProvider .header {
	margin-bottom: 48px;
}

.containerMatches {
	text-align: left;
}

.containerMatches > div:not(:last-child) {
	margin-bottom: 16px;
}

.card {
	padding:  16px;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
}

.card svg {
	margin-bottom: 8px;
}

.containerMatches .cardHeader {
	display: flex;
}

.containerMatches .cardHeader h5 {
	margin-bottom: 4px;
}

.containerMatches .cardHeader p {
	margin:  0;
	color: #757575;
}

.containerMatches .cardHeader > *:first-child {
	margin-right: 16px;
}

.footer {
	margin-top: 48px;
}

.loadingFindProvider > *:not(:last-child) {
	margin-bottom: 16px;
}

.profileBtn {
	/*text-align: right;*/
	color: #2979FF;
	margin-top: 8px;
	cursor: pointer;
}

.profileBtn:hover {
	color: #2962FF;
	text-decoration: underline;
}

.retakeSurvey {
	cursor: pointer;
}

.retakeSurvey:hover {
	color: #212121;
	text-decoration: underline;
}

/*Responsive Layout*/
@media only screen and (min-width: 600px) {
  .containerFindProvider  {
    max-width: 1200px;
    margin: auto;
  }
}
/*Responsive Layout*/

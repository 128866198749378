.MuiCalendarPicker-root {
	border-radius: 12px;
	background-color: #F5F5F5;
}

.MuiCalendarPicker-root button.Mui-selected,
.MuiCalendarPicker-root button.Mui-selected:focus,
.MuiCalendarPicker-root button.Mui-selected:hover  {
	background-color: #2979FF;
	color: #ffffff;
}
.container {
	padding: 16px;
	background-color: #2979FF;
	color: #E3F2FD;
	border-radius: 24px;
	position: relative;
}

.container h5:not(:last-child) {
	margin-bottom: 4px;
}

.container p {
	margin:  0;
	color: #BBDEFB;
}

.container .rescheduleBtn {
	position: absolute;
	top: 16px;
	right: 16px;
	background-color: transparent;
	color:  #ffffff;
	border:  solid 2px #ffffff;
}

.container .rescheduleBtn:hover {
	background-color: rgba(0,0,0,0.1);
}

.header {
	display: flex;
}

.header > *:first-child {
	margin-right: 16px;
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap:wrap;
}

.timeslot {
	background-color: rgba(0,0,0,0.2);
	border-radius: 12px;
	margin-top: 24px;
	padding: 16px;
	/*word-spacing: 0.2rem;*/
	font-weight: 500;
	color: #ffffff;
}

.timeslot p {
	font-weight:  300;
	color:  #BBDEFB;
}

.timeslot:not(:last-child) {
	margin-bottom: 24px;
}

.timeslotBtns {
	margin-top: 16px;
	color: #FFFFFF;
	width:  100%;
}

.timeslotBtns button:not(:last-child) {
	margin-right: 8px;
}

.container .chip {
	font-family: inherit;
	color:  #ffffff;
	margin-bottom: 8px;
}

.container .chip:not(:last-child) {
	margin-right: 8px;
}

.container .chip svg {
	color: #ffffff;
}

.container .chipsContainer {
	display:  flex;
	flex-wrap: wrap;
	margin-bottom: 8px;
}

.container .videoBtn {
	background-color: white;
	color: #2979FF;
	border: solid 2px white;
}

.container .videoBtn:hover {
	background-color: #BBDEFB;
	color: #2962FF;
	border-color: #BBDEFB;
}

.container .videoBtn:disabled{
	background-color: #0D47A1;
	color: rgba(255,255,255,0.5);
	border: solid 2px #0D47A1;
}

.phoneMessage {
	margin-top: 16px;
	display:  flex;
	align-items: flex-end;
}

.phoneMessage svg {
	margin-right:  8px;
}


.containerNoAppointments {
	background-color: transparent;
	color: #424242;
	border: solid 2px #e5e5e5;
	text-align: center;
	padding: 32px;
}

.containerNoAppointments p {
	color: #757575;
}

.containerNoAppointments h5:not(:last-child) {
	margin-bottom: 8px;
}

.containerNoAppointments svg {
	color: #757575;
	/*font-size: 2rem;*/
	margin-bottom: 8px;
}

.containerNoAppointments button {
	margin-top: 32px;
}
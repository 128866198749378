body {
  margin: 0;
  color:  #424242;
  font-family: 'Airbnb Cereal', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Airbnb Cereal";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/AirbnbCerealLight.woff") format("woff"),
    url("./fonts/AirbnbCerealLight.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb Cereal";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/AirbnbCerealBook.woff") format("woff"),
    url("./fonts/AirbnbCerealBook.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb Cereal";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/AirbnbCerealMedium.woff") format("woff"),
    url("./fonts/AirbnbCerealMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb Cereal";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/AirbnbCerealBold.woff") format("woff"),
    url("./fonts/AirbnbCerealBold.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb Cereal";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/AirbnbCerealExtraBold.woff") format("woff"),
    url("./fonts/AirbnbCerealExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb Cereal";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/AirbnbCerealBlack.woff") format("woff"),
    url("./fonts/AirbnbCerealBlack.ttf") format("truetype");
}

@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Merriweather-Regular.ttf") format("truetype");
}

body h1 {
  font-size: 3rem;
  font-weight:  500;
  margin:  0;
}

body h2 {
  font-size: 2rem;
  font-weight:  500;
  margin:  0;
}

body h3 {
  font-size: 1.8rem;
  font-weight:  500;
  margin:  0;
}

body h4 {
  font-size: 1.4rem;
  font-weight:  600;
  margin:  0;
}

body h5 {
  font-size: 1.1rem;
  font-weight:  600;
  margin:  0;
}

body h1:not(:last-child) {
  margin-bottom: 48px;
}

body h2:not(:last-child) {
  margin-bottom: 32px;
}

body h3:not(:last-child) {
  margin-bottom: 24px;
}

body h4:not(:last-child) {
  margin-bottom: 24px;
}

body h5:not(:last-child) {
  margin-bottom: 8px;
}

body p {
  font-size:  1rem;
  margin: 0;
}

body p:not(:last-child) {
  margin-bottom: 4px;
}

body a {
  font-weight: 500;
  text-decoration: none;
  color: #424242;
}

body a:hover {
  text-decoration: underline;
}
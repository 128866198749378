.containerProviderProfile {
	max-width:  1200px;
	margin: auto;
	padding-left:  16px;
	padding-right:  16px;
}

.containerProviderProfile h1 {
	margin-bottom: 0!important;
}

.card {
	height:  100%;
	border:  solid 2px #e5e5e5;
	padding:  32px 24px 24px 24px;
	border-radius:  24px;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

 .containerProviderProfile .card h3 {
	font-family: "Merriweather";
	font-size: 1.8rem;
	font-weight: 600;
	line-height: 150%;
	margin-top:  0px;
	margin-bottom:  0px;
}

 .containerProviderProfile .card h5 {
	font-size: 1.2rem;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom:  16px!important;
}

 .containerProviderProfile .card p {
	font-size: 1.2rem;
	margin-top:  0px;
	margin-bottom:  0px;	
}

.header {
	margin-bottom:  16px;
}

 .containerProviderProfile .header p {
	font-size:  1.2rem;
	margin: 0;
}

/*.profilePic {
	width:  100%;
	height:  100%;
}*/

.profilePic {
  position: relative;
}

.profilePic::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.profilePic img {
	position: absolute;
	width:  100%;
	height:  100%;
	object-fit:  cover;
	border-radius: 24px;
}

.containerProviderProfile .subHeader {
	display: flex;
	gap: 4px;
	font-weight: 500;
	color: #FFA000;
}

@media only screen and (min-width: 600px) {
}

.container {
  height:  56px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 0px 16px;
  color:  #424242;
  background-color: #ffffff;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.container h1, .container h1:not(:last-child) {
  margin:  0;
  font-size: 1rem;
  font-weight:  600;
}

.container p {
  margin:  0;
  font-size: 0.8rem;
}

.btnClose {
  position:  absolute;
  top:  0;
  left:  16px;
  height:  100%;
  display:  flex;
  align-items:  center;
}

.btnStartOver {
  position:  absolute;
  top:  0;
  right:  16px;
  height:  100%;
  display:  flex;
  align-items:  center;
}

.dot {
  display: inline;
  font-size: 0.7rem;
  color:  #00C853;
  margin-right: 4px;
}

.main {
  text-align: center;
}
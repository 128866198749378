.MuiInputBase-root.MuiOutlinedInput-root {
	border-radius: 12px;
}

.MuiInputBase-root, .MuiInputLabel-root {
	font-family: 'Airbnb Cereal'!important;
}

.MuiTextField-root fieldset {
	border: solid 2px #e5e5e5;
	border-radius: 12px;
}

.MuiTextField-root .Mui-focused fieldset {
	border-color: #2979FF!important;
}

.MuiTextField-root .Mui-error fieldset {
	border-color: #D50000!important;
}

.MuiTextField-root:hover .MuiInputBase-root fieldset {
	border: solid 2px #424242;
}

.MuiTextField-root .MuiInputBase-colorSuccess.Mui-focused  fieldset {
	border-color: #00C853!important;
}

.MuiTextField-root .MuiInputAdornment-root {
	margin-right: 8px;
}
.MuiChip-root.MuiChip-colorPrimary {
	background-color: rgba(0,0,0,0.5);
	font-weight: 500;
}

.MuiChip-root.MuiChip-colorSuccess {
	background-color: #00BFA5;
}

.MuiChip-root.MuiChip-colorError {
	background-color: #FF5252;
}
.react-tel-input .special-label {
    display: none;
}

.react-tel-input .form-control {
    border-radius: 12px!important;
    border: solid 2px #e5e5e5!important;
}

.react-tel-input .form-control:hover {
    border: solid 2px #424242!important;
} 

.react-tel-input .form-control:focus {
    border: solid 2px #2979FF!important;
    box-shadow: none!important;
} 

.react-tel-input .form-control.invalid {
    border: solid 2px #D50000!important;
} 

.react-tel-input .form-control.invalid:hover {
    border: solid 2px #D50000!important;
} 

.react-tel-input .form-control.invalid:focus {
    border: solid 2px #D50000!important;
    box-shadow: none!important;
} 

.react-tel-input .form-control.valid:hover {
    border: solid 2px #00C853!important;
} 

.react-tel-input .form-control.valid:focus {
    border: solid 2px #00C853!important;
    box-shadow: none!important;
} 
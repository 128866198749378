.MuiTypography-root, 
.MMuiDialogTitle-root, 
.MuiDialogContent-root, 
.MuiDialogContentText-root,
.MuiDialogActions-root,
.MuiButton-root {
	font-family: 'Airbnb Cereal'!important;
}

.MuiTypography-root, 
.MMuiDialogTitle-root, 
.MuiDialogContent-root, 
.MuiDialogContentText-root {
	color:  #424242;
}

.MuiDialogActions-root .MuiButton-root {
	color:  #2979FF;
}

.MuiPaper-root.MuiDialog-paper {
	border-radius:  12px;
	box-shadow:  none;
}

h2.MuiDialogTitle-root:not(:last-child) {
	margin-bottom: 0;
}

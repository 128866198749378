.containerPaymentMethodCard {
	padding:  16px;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header p {
	text-transform: capitalize;
}


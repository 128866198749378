.container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.chatContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.datePicker {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 74px;
  height: 74px;
  z-index: 1001;
}

.datePicker input {
  border: none !important;
  padding:  0px 0px 0px 16px!important;
  height:  74px;
}

.datePicker fieldset {
  border: none !important;
}

.datePicker p {
  display: none;
}

.datePicker > * {
  width:  100%;
  border-top: solid 2px #e0e0e0!important;
  border-right: none!important;
  border-bottom:  none!important;
  background-color: white;
}

.datePicker > *:hover,
.datePicker > *:focus-within {
  border: solid 2px #bdbdbd;
  background-color: #F5F5F5;
  border-right: none!important;
  border-left:  none!important;
}

@media only screen and (min-width: 768px) {
}

.MuiAlert-root.MuiAlert-standard {
	border-radius: 8px!important;
}

.MuiAlert-message {
	font-family: 'Airbnb Cereal';
}

.MuiAlert-root.MuiAlert-standardSuccess {
	background-color: #B9F6CA;
}

.MuiAlert-standardSuccess .MuiAlert-icon {
	color: rgb(30, 70, 32);;
}

.MuiAlert-root.MuiAlert-outlinedInfo {
	border:  solid 2px #2979FF;
	color:  #2979FF;
}

.MuiAlert-root.MuiAlert-outlinedInfo .MuiAlert-icon {
	color:  #2979FF;
}

.MuiAlert-root.MuiAlert-outlinedWarning {
	border-width: 2px;
}

.container {
	height: 72px;
	width: 72px;
	min-height: 72px;
	min-width: 72px;
}

.container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 12px;
}
.containerPatientNoTasksCard {
	background-color: transparent;
	color: #424242;
	border: solid 2px #e5e5e5;
	border-radius:  24px;
	padding: 16px;
	display: flex;
	gap: 16px;
}

.containerPatientNoTasksCard p {
	color: #757575;
}

.containerPatientNoTasksCard h5:not(:last-child) {
	margin-bottom: 8px;
}

.containerPatientNoTasksCard svg {
	color: #00BFA5;
}
.containerProviderProfileBook {
	position: fixed;
	bottom:  0;
	left: 0;
	right: 0;
	padding:  16px;
	background-color: #ffffff;
	border-top: solid 2px #e5e5e5;
}

.containerProviderProfileBook .row {
	display: flex;
	justify-content: space-between;
	align-items:  center;
	margin-bottom:  16px;
}

.containerProviderProfileBook h5, .containerProviderProfileBook p {
	margin:  0!important;
}

.bookConsultation {
	text-decoration: underline;
	cursor: pointer;
}

.bookConsultation:hover {
	color: black;
}
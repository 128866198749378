.MuiOutlinedInput-root .MuiSelect-select.MuiSelect-outlined {
	border-radius: 12px;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	border-width: 2px;
	border-color: #e0e0e0;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #2979FF!important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
	border-color: #D50000!important;
}

.containerAppointmentHistory{
	padding: 48px 16px 64px 16px;
}

.containerAppointmentHistory > div:not(:last-child) {
	margin-bottom: 32px;
}

.containerNoPastAppointments {
	background-color: transparent;
	color: #424242;
	border: solid 2px #e5e5e5;
	border-radius: 24px;
	text-align: center;
	padding: 32px;
}

.containerNoPastAppointments p {
	color: #757575;
}

.containerNoPastAppointments svg {
	color: #424242;
	margin-bottom: 8px;
}

/*Responsive Layout*/
@media only screen and (min-width: 600px) {
	.containerAppointmentHistory {
		max-width: 1200px;
		margin: auto;
	}
}
/*Responsive Layout*/